import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PATHS_ROUTES } from './constants';

const Home = React.lazy(() =>
  import('./pages/Home').then((module) => ({ default: module.Home }))
);
const ConsultOrders = React.lazy(() =>
  import('./pages/ConsultOrders').then((module) => ({
    default: module.ConsultOrders
  }))
);
const ServiceOrders = React.lazy(() =>
  import('./pages/ServiceOrders').then((module) => ({
    default: module.ServiceOrders
  }))
);
const Error = React.lazy(() =>
  import('./pages/Error').then((module) => ({
    default: module.Error
  }))
);
const LogIn = React.lazy(() =>
  import('./pages/LogIn').then((module) => ({
    default: module.LogIn
  }))
);
const PrivateRoute = React.lazy(() =>
  import('./routes/PrivateRoute').then((module) => ({
    default: module.PrivateRoute
  }))
);
const PublicRoute = React.lazy(() =>
  import('./routes/PublicRoute').then((module) => ({
    default: module.PublicRoute
  }))
);

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-screen">
            Loading...
          </div>
        }
      >
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path={`/${PATHS_ROUTES.login}`} element={<LogIn />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={PATHS_ROUTES.home} element={<Home />} />
            <Route
              path={`/${PATHS_ROUTES.consultOrders}`}
              element={<ConsultOrders />}
            />
            <Route
              path={`/${PATHS_ROUTES.serviceOrders}`}
              element={<ServiceOrders />}
            />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
