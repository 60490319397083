import { Middleware } from '@reduxjs/toolkit';

import { setAuthorized, setUser } from 'services/auth';

import { ErrorResponseEnum } from 'types';

export const authMiddleware: Middleware =
  (store) => (next) => (action: any) => {
    if (action.type.endsWith('/rejected')) {
      const { statusCode, message } = action.payload?.data || {};
      if (statusCode === 401 && message === ErrorResponseEnum.unAuthorized) {
        store.dispatch(setAuthorized(false));
        store.dispatch(setUser(null));
      }
    }
    return next(action);
  };
