import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { ordersApi } from 'services/orders';
import { authApi } from 'services/auth';

import { authMiddleware } from './middlewares';

import { authSlice } from './auth';
import { settings } from './settings';

const persistAuthConfig = {
  key: 'auth',
  storage: storage
};

const persistSettingsConfig = {
  key: 'settings',
  storage: storage
};

const persistSettingsReducer = persistReducer(
  persistSettingsConfig,
  settings.reducer
);
const persistAuthReducer = persistReducer(persistAuthConfig, authSlice.reducer);

export const createStore = () =>
  configureStore({
    reducer: {
      settings: persistSettingsReducer,
      auth: persistAuthReducer,
      [ordersApi.reducerPath]: ordersApi.reducer,
      [authApi.reducerPath]: authApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat([ordersApi.middleware, authApi.middleware, authMiddleware])
  });

export const store = createStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
