import { TAllOrderConsults } from './consult-orders';
import { TAllOrderService } from './services-orders';

export enum ProjectNameEnums {
  AllProjects = 'All Projects',
  PortalCurp = 'Portal Curp',
  AsistenteCertificadoCO = 'Asistente Certificado CO',
  AsistenteCertificadoAR = 'Asistente Certificado AR',
  AsistenteCertificadoMX = 'Asistente Certificado MX',
  ConstansiasPanelesMX = 'Constansias Paneles MX',
  AsistenteCertificadoCL = 'Asistente Certificado CL',
  AsistenteCertificadoPE = 'Asistente Certificado PE',
  AsistenteCertificadoCR = 'Asistente Certificado CR'
}

export enum ServiceNamesEnum {
  antecedentes = 'Antecedentes',
  matrimonio = 'Matrimonio',
  nacimiento = 'Nacimiento',
  sena = 'Sena',
  tradicion = 'Tradicion',
  afiliacion = 'Afiliacion',
  curp = 'CURP',
  constancias = 'Constancias',
  cuil = 'Cuil',
  negativa = 'Negativa',
  afip = 'Afip',
  cedulas = 'Cedulas',
  tse = 'TSE',
  vehiculos = 'Vehiculos',
  avaluoFiscal = 'Avaluo Fiscal',
  estudio = 'Estudio'
}

export enum ProjectRoutesEnum {
  argentina = 'argentina',
  peru = 'peru',
  chile = 'chile',
  constaciasPenales = 'constacias-penales',
  costaRica = 'costa-rica',
  asistenteCertificadoMexico = 'asistente-certificado-mexico',
  portalCurp = 'portal-curp',
  columbia = 'columbia'
}

export type TransformedOrderService = {
  [K in keyof TAllOrderService]: K extends 'created_at'
    ? string
    : K extends 'updated_at'
      ? string
      : K extends 'checked'
        ? string
        : K extends 'is_send_letter'
          ? string
          : TAllOrderService[K];
};

export type TransformedOrderConsult = {
  [K in keyof TAllOrderConsults]: K extends 'created_at'
    ? string
    : K extends 'updated_at'
      ? string
      : K extends 'checked'
        ? string
        : K extends 'is_send_letter'
          ? string
          : TAllOrderConsults[K];
};

export interface IColumn<T extends string> {
  key: T;
  label: string;
}

// export type TAllOrders = TAllOrderConsults | TAllOrderService;
